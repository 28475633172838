import React from "react";
import { Link } from "react-router-dom";
import Particle from '../../componets/Particle';
import option3 from "../../assets/json/option3.json"; // Adjust the path if necessary
import Engdemy from "../../assets/Images/Engdemy.png";
import './ContactPage.css';

function ContactPage() {
    return (
        <div className="contact-container">
            <Particle option={option3} />
            <div className="contact-content">
                <div className="contact-form-container">
                    <header className="contact-header">
                        <h1>Contact <span>Us</span></h1>
                    </header>
                    <main className="contact-main">
                        <p>
                            If you have any questions or need assistance, feel free to reach out to us. We're here to help!
                        </p>
                        <form className="contact-form">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" placeholder="Enter your Name" required />
                            
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" placeholder="Enter your Email" required />
                            
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" rows="4" placeholder="Type your Message...." required></textarea>
                            
                            <button type="submit" className="contact-button">Send Message</button>
                        </form><br/>
                        <Link to="/about">
                            <button className="explore-button">About Us</button>
                        </Link>
                    </main>
                </div>
                <div className="contact-image-container">
                    <img src={Engdemy} alt="Contact Us" />
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
