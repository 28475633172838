import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { LOGOUT } from '../redux/const/actionsTypes';
import './HomeLayout.css';
import { toast } from 'react-toastify';
import Engdemy from "../assets/Images/Engdemy.png";

const HomeLayout = ({ children, auth }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const savedAuthData = JSON.parse(localStorage.getItem('authData'));
        setAuthenticated(!!savedAuthData || !!auth.authData);
    }, [auth]);

    const handleLogOut = (e) => {
        e.preventDefault();
        dispatch({ type: LOGOUT });
        localStorage.removeItem('authData');
        toast.success("Logged out successfully");
        setAuthenticated(false);
        navigate('/');
    };

    const navigateToProfile = () => {
        navigate('/profile');
    };

    return (
        <div className={`layout ${isScrolled ? 'scrolled' : ''}`}>
            <header className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
                <div className="navbar-content">
                    <Link to="/" className="logo">
                        <img src={Engdemy} alt="Home" className="logo-image" />
                        <h3>Engdemy Education Inc</h3>
                    </Link>
                    <div className="center-options">
                        <nav className="nav-links">
                            <Link to="/">Home</Link>
                            <Link to="/course">Courses</Link>
                            <Link to="/contact">Contact Us</Link>
                            <Link to="/about">About Us</Link>
                        </nav>
                    </div>
                    <div className="right-options">
                        {authenticated && (
                            <>
                                <span onClick={navigateToProfile} className="profile-icon">
                                    <i className="fas fa-user"></i> {auth.authData.username}
                                </span>
                                <Link to="/" onClick={handleLogOut} className="logout">
                                    Logout
                                </Link>
                            </>
                        )}
                        {!authenticated && (
                            <>
                                <Link to="/login" className="login">Login</Link>
                                <Link to="/signup" className="signup">Signup</Link>
                            </>
                        )}
                    </div>
                </div>
            </header>
            {/* <main className="content">
                {children}
            </main> */}
        </div>
    );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(HomeLayout);
