import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserById, updateUser, updatePassword } from '../../api'; 
import Particle from '../../componets/Particle';
import option2 from "../../assets/json/option2.json";
import { toast } from 'react-toastify';
import './ProfilePage.css';

function ProfilePage() {
    const [auth, setAuth] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [formData, setFormData] = useState({});
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getUserDetails(); 
    }, []);

    const getUserDetails = async () => {
        try {
            const localUser = JSON.parse(localStorage.getItem("user_info"));
            const result = await getUserById(localUser.result._id);
            setAuth(result.data);
            setFormData({
                ...result.data,
                dob: result.data.dob ? new Date(result.data.dob).toISOString().split('T')[0] : '',
            });
        } catch (error) {
            toast.error("Error fetching user details");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({ ...passwordData, [name]: value });
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setIsChangingPassword(false);
    };

    const handleSaveClick = async () => {
        try {
            const localUser = JSON.parse(localStorage.getItem("user_info"));
            await updateUser(localUser.result._id, {
                ...formData,
                dob: new Date(formData.dob).toISOString().split('T')[0]
            });
            setAuth(formData);
            setIsEditing(false);
            toast.success("Profile updated successfully!");
        } catch (error) {
            toast.error("Error updating profile");
        }
    };

    const handleChangePasswordClick = () => {
        setIsChangingPassword(true);
        setIsEditing(false); 
    };

    const handleSavePasswordClick = async () => {
        setPasswordError('');
        const localUser = JSON.parse(localStorage.getItem("user_info"));
        const { currentPassword, newPassword, confirmPassword } = passwordData;

        if (newPassword !== confirmPassword) {
            setPasswordError('New passwords do not match.');
            toast.error("New passwords do not match.");
            return;
        }

        try {
            const result = await updatePassword(localUser.result._id, { currentPassword, newPassword });
            if (result.data.success) {
                toast.success("Password updated successfully.");
                setIsChangingPassword(false);
                setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
            } else {
                setPasswordError(result.data.message || 'Error updating password. Please check your current password and try again.');
                toast.error(result.data.message || "Error updating password. Please check your current password and try again.");
            }
        } catch (error) {
            setPasswordError('Error updating password. Please check your current password and try again.');
            toast.error("Error updating password. Please check your current password and try again.");
        }
    };

    return (
        <div className="profile-page">
            <Particle option={option2} />
            <div className="profile-container">
                <h1 className="profile-heading">Profile Dashboard</h1>
                <div className="profile-details">
                    {!isChangingPassword && (
                        <>
                            <p className="highlight"><strong>Username:</strong> {isEditing ? (
                                <input 
                                    type="text" 
                                    name="firstName" 
                                    value={formData.firstName} 
                                    onChange={handleInputChange} 
                                />
                            ) : auth.firstName}</p>
                            <p className="highlight"><strong>Email:</strong> {isEditing ? (
                                <input 
                                    type="email" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleInputChange} 
                                />
                            ) : auth.email}</p>
                            {isEditing && (
                                <>
                                    <p><strong>Age:</strong> 
                                        <input 
                                            type="number" 
                                            name="age" 
                                            value={formData.age || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                    <p><strong>Gender:</strong> 
                                        <input 
                                            type="text" 
                                            name="gender" 
                                            value={formData.gender || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                    <p><strong>Date of Birth:</strong> 
                                        <input 
                                            type="date" 
                                            name="dob" 
                                            value={formData.dob || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                    <p><strong>Field of Study:</strong> 
                                        <input 
                                            type="text" 
                                            name="fieldOfStudy" 
                                            value={formData.fieldOfStudy || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                    <p><strong>City:</strong> 
                                        <input 
                                            type="text" 
                                            name="city" 
                                            value={formData.city || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                    <p><strong>State:</strong> 
                                        <input 
                                            type="text" 
                                            name="state" 
                                            value={formData.state || ''} 
                                            onChange={handleInputChange} 
                                        />
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </div>
                {isEditing && (
                    <button className="save-profile-button" onClick={handleSaveClick}>Save</button>
                )}
                {!isEditing && !isChangingPassword && (
                    <button className="edit-profile-button" onClick={handleEditClick}>Edit Profile</button>
                )}
                {isChangingPassword ? (
                    <div className="change-password-container">
                        <h2>Change Password</h2>
                        <input 
                            type="password" 
                            name="currentPassword" 
                            placeholder="Current Password" 
                            value={passwordData.currentPassword} 
                            onChange={handlePasswordChange} 
                        />
                        <input 
                            type="password" 
                            name="newPassword" 
                            placeholder="New Password" 
                            value={passwordData.newPassword} 
                            onChange={handlePasswordChange} 
                        />
                        <input 
                            type="password" 
                            name="confirmPassword" 
                            placeholder="Confirm New Password" 
                            value={passwordData.confirmPassword} 
                            onChange={handlePasswordChange} 
                        />
                        {passwordError && <p className="error-message">{passwordError}</p>}
                        <button className="save-password-button" onClick={handleSavePasswordClick}>Save Password</button>
                    </div>
                ) : ( 
                    <button className="change-password-button" onClick={handleChangePasswordClick}>Change Password</button>
                )}
            </div>
        </div>
    );
}

export default ProfilePage;
