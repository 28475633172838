import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { celpiphome, celpipgif, Book1, Book2, Book3, Book4, Book5, Mam, speak, write, read,listen, user,questionimg,aiimg,test } from "../assets/Images/impoimg"; // Image Path
import './CelpipHome.css';

const CelpipHome = () => {

    const [usersCount, setUsersCount] = useState(10);
    const [questionsCount, setQuestionsCount] = useState(10);
    const [testsCount, setTestsCount] = useState(10);
    const [hasAnimated, setHasAnimated] = useState(false); // To control counting trigger
    const navigate = useNavigate();

    const handleDemoClick = () => {
        navigate('/demo');
    };

    const handlePracticeClick = () => {
        navigate('/practice');
    };

    // Scroll effect using intersection observer
    useEffect(() => {
        // Force start at the top of the page
        window.scrollTo(0, 0);

        const elements = document.querySelectorAll('.celpip-home-section, .about-test-container');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                } else {
                    entry.target.classList.remove('fade-in');
                }
            });
        });

        elements.forEach((el) => observer.observe(el));
    }, []);

     // Dynamic counting trigger when the stats section comes into view
     useEffect(() => {
        const statsSection = document.querySelector('.stats-section');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !hasAnimated) {
                        startCounting();
                        setHasAnimated(true); // Prevents counting again on scroll
                    }
                });
            },
            { threshold: 0.3 } // Trigger when 30% of the stats section is visible
        );
        if (statsSection) observer.observe(statsSection);

        return () => observer.disconnect(); // Cleanup observer when component unmounts
    }, [hasAnimated]);

    // Handle dynamic counting logic
    const startCounting = () => {
        // Users count - Increments by 200
        const usersInterval = setInterval(() => {
            setUsersCount((prevCount) => (prevCount < 1286 ? prevCount + 100 : 1286));
        }, 100);

        // Questions count - Increments by 50
        const questionsInterval = setInterval(() => {
            setQuestionsCount((prevCount) => (prevCount < 500 ? prevCount + 50 : 500));
        }, 100);

        // Tests count - Increments by 5
        const testsInterval = setInterval(() => {
            setTestsCount((prevCount) => (prevCount < 50 ? prevCount + 5 : 50));
        }, 100);

        // Clear intervals when targets are reached
        setTimeout(() => {
            clearInterval(usersInterval);
            clearInterval(questionsInterval);
            clearInterval(testsInterval);
        }, 2000); // Stops counting after 2 seconds
    };


    return (
        <div className="celpip-home-wrapper">
            {/* First Page */}
            <section className="celpip-home-section first-page">
                <div className="celpip-home-content">
                    <div className="celpip-home-text">
                        <h1 className="celpip-home-title">
                            CELPIP PREPARATION <br />
                            SECTIONAL TESTS <br />
                            FULL TESTS <br />
                            WITH AI CHECKING
                        </h1>

                        <div className="celpip-button-container">
                            <button
                                className="celpip-demo-button"
                                onClick={handleDemoClick}
                            >
                                BOOK A FREE DEMO
                            </button>

                            <button
                                className="celpip-practice-button"
                                onClick={handlePracticeClick}
                            >
                                PRACTICE NOW
                            </button>
                        </div>

                        <p className="celpip-live-class-text">LIVE CLASS WITH TRAINER !!</p>
                    </div>

                    <img
                        src={celpiphome}
                        alt="Celpip Home"
                        className="celpip-home-image"
                    />
                </div>
            </section>

            {/* Second Page */}
            <section className="celpip-home-section second-page">
                <div className="about-test-container">
                    <img src={celpipgif} alt="Celpip Gif" className="about-test-image" />
                    <div className="about-test-text">
                        <h2>ABOUT THE TEST</h2>
                        <p>
                            The CELPIP Test is available in two versions: the CELPIP-General Test and
                            the CELPIP-General LS Test. The CELPIP-General Test has four components—
                            Listening, Reading, Writing, and Speaking. The total test time for the
                            CELPIP-General Test is about 3 hours.
                        </p>
                        <p>
                            The CELPIP Test is fully computer delivered, providing test ​takers the opportunity 
                            to complete all test components in ​one sitting with no additional appointments, 
                            interviews, or ​test sittings required.
                            CELPIP Test Takers use a computer mouse and keyboard to complete Reading
                            and Writing components, and a microphone and headset to complete Listening
                            and Speaking components. Computerized tests offer test takers a quick ​and reliable testing experience.
                        </p>
                        <button
                            className="practice-now-button"
                            onClick={handlePracticeClick}
                        >
                            PRACTICE NOW
                        </button>
                    </div>
                </div>
            </section>
            {/* Third Page */}
            <section className="celpip-home-section third-page">
                <div className="mam-image-container">
                    <img src={Mam} alt="Mam" className="mam-image" />
                </div>
                <div className="books-content">
                    <h2 className="ebooks-title">EBOOKS - BY NAMRATA PALTA</h2>
                    <div className="books-container">
                        <img src={Book1} alt="Book 1" className="book-image book1" />
                        <img src={Book2} alt="Book 2" className="book-image book2" />
                        <img src={Book3} alt="Book 3" className="book-image book3" />
                        <img src={Book4} alt="Book 4" className="book-image book4" />
                        <img src={Book5} alt="Book 5" className="book-image book5" />
                    </div>
                    <div className="blink-text">
                        <p>INCLUDED WITH PRACTICE ​MATERIAL</p>
                    </div>
                    <div className="author-info">
                        <p>Author of 6 English language books</p>
                    </div>
                </div>
            </section>

         {/* Fourth Page */}
<section className="celpip-home-section fourth-page">
    <div className="celpip-content">
        <div className="celpip-section speaking-section">
            <img src={speak} alt="Speaking" className="celpip-icon" />
            <h2 className="celpip-heading">SPEAKING</h2>
            <ul className="celpip-tasks speaking-tasks">
                <li><span className="task-highlight">Task 1</span> Giving Advice</li>
                <li><span className="task-highlight">Task 2</span> Talking about a Personal Experience</li>
                <li><span className="task-highlight">Task 3</span> Describing a scene</li>
                <li><span className="task-highlight">Task 4</span> Making Predictions</li>
                <li><span className="task-highlight">Task 5</span> Comparing and Persuading</li>
                <li><span className="task-highlight">Task 6</span> Dealing with a difficult situation</li>
                <li><span className="task-highlight">Task 7</span> Expressing an opinion</li>
                <li><span className="task-highlight">Task 8</span> Describing an Unusual situation</li>
            </ul>
        </div>

        <div className="celpip-section writing-section">
            <img src={write} alt="Writing" className="celpip-icon" />
            <h2 className="celpip-heading">WRITING</h2>
            <ul className="celpip-tasks writing-tasks">
                <li><span className="task-highlight">Task 1</span> Writing an Email</li>
                <li><span className="task-highlight">Task 2</span> Respond to a survey</li>
            </ul>
        </div>

        <div className="celpip-section reading-section">
            <img src={read} alt="Reading" className="celpip-icon" />
            <h2 className="celpip-heading">READING</h2>
            <ul className="celpip-tasks reading-tasks">
                <li><span className="task-highlight">Task 1</span> Reading Correspondence</li>
                <li><span className="task-highlight">Task 2</span> Reading to apply a diagram</li>
                <li><span className="task-highlight">Task 3</span> Reading for information</li>
                <li><span className="task-highlight">Task 4</span> Reading for Viewpoints</li>
            </ul>
        </div>

        <div className="celpip-section listening-section">
            <img src={listen} alt="Listening" className="celpip-icon" />
            <h2 className="celpip-heading">LISTENING</h2>
            <ul className="celpip-tasks listening-tasks">
                <li><span className="task-highlight">Part 1</span> Listening to Problem Solving</li>
                <li><span className="task-highlight">Part 2</span> Listening to Daily Life</li>
                <li><span className="task-highlight">Part 3</span> Listening for Information</li>
                <li><span className="task-highlight">Part 4</span> Listening to a News Item</li>
                <li><span className="task-highlight">Part 5</span> Listening to a Discussion</li>
                <li><span className="task-highlight">Part 6</span> Listening to Viewpoints</li>
            </ul>
        </div>
    </div>
</section>

<section className="stats-section">
                {/* Users Counter */}
                <div className="stat-item">
                    <img src={user} alt="Users" className="stat-image" />
                    <div className="counter">{usersCount}+</div>
                    <div className="label">USERS</div>
                </div>

                {/* Questions Counter */}
                <div className="stat-item">
                    <img src={questionimg} alt="Questions" className="stat-image" />
                    <div className="counter">{questionsCount}+</div>
                    <div className="label">QUESTIONS</div>
                </div>

                {/* Tests Counter */}
                <div className="stat-item">
                    <img src={test} alt="Tests" className="stat-image" />
                    <div className="counter">{testsCount}+</div>
                    <div className="label">SECTION TESTS</div>
                </div>

                {/* AI Image */}
                <div className="stat-item">
                    <img src={aiimg} alt="AI Scoring" className="stat-image" />
                    <div className="label">AI Checked Scoring</div>
                </div>
            </section>

        </div>
    );
};

export default CelpipHome;
