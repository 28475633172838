import React, { useState, useEffect, useRef } from 'react';
import './SpeakingTest.css';
import { fetchQuestions } from '../../api/index';
import { startRecording as startRecordingUtility, stopRecording as stopRecordingUtility } from './Common';

const SpeakingTest = () => {
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [prepareTime, setPrepareTime] = useState(0);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [imageSelectionTime, setImageSelectionTime] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showThirdImage, setShowThirdImage] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [transcription, setTranscription] = useState(null);

  const audioContextRef = useRef(null);
  const gumStreamRef = useRef(null);
  const recRef = useRef(null);
  const recordingIntervalRef = useRef(null);

  // Fetch questions
  useEffect(() => {
    const getQuestions = async () => {
      try {
        const { data } = await fetchQuestions();
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    getQuestions();
  }, []);

  // When the current index changes, reset the question, preparation time, and recording time
  useEffect(() => {
    if (currentIndex !== null) {
      const question = questions[currentIndex];
      setCurrentQuestion(question);
      setPrepareTime(question.preparationTime);
      setRecordingTime(question.recordingTime);
      setAudioUrl(null); // Reset audio preview when moving to next question
      setIsRecordingDone(false); // Reset recording state
      setShowThirdImage(false); // Reset image selection
      if (currentIndex === 4 && question.img && question.img2) {
        setImageSelectionTime(question.preparationTime);
      }
    }
  }, [currentIndex, questions]);

  // Countdown for preparation time
  useEffect(() => {
    if (prepareTime > 0) {
      const countdownInterval = setInterval(() => {
        setPrepareTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownInterval);
            startActualRecording(); // Start recording after preparation time ends
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [prepareTime]);

  // Countdown for image selection time (if applicable)
  useEffect(() => {
    if (imageSelectionTime > 0) {
      const selectionCountdown = setInterval(() => {
        setImageSelectionTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(selectionCountdown);
            setShowThirdImage(true); // Show third image after selection countdown
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(selectionCountdown);
    }
  }, [imageSelectionTime]);

  // Recording countdown
  useEffect(() => {
    if (isRecording) {
      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(recordingIntervalRef.current);
            debouncedStopActualRecording(); // Stop recording after full time
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    // Cleanup intervals
    return () => {
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
    };
  }, [isRecording]);

  // Start the test by setting the first question
  const startTest = () => {
    setCurrentIndex(0);
  };

  // Navigate to the next question
  const nextQuestion = () => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      alert('Test completed!');
    }
  };

  // Navigate back to the previous question
  const goBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Start the actual recording
  const startActualRecording = () => {
    setIsRecording(true);
    startRecordingUtility(audioContextRef, gumStreamRef, recRef, setRecordingTime);
  };

  // Stop the actual recording
  const stopActualRecording = async () => {
    if (isStopping || !isRecording) return; // Prevent double execution
    setIsStopping(true);

    console.log("Stopping actual recording");
    setIsRecording(false);
    setIsRecordingDone(true);

    const audioBlob = await stopRecordingUtility(recRef);
    //setIsStopping(false);

    const audioFile = new File([audioBlob], "audio.webm", { type: "audio/webm" });

    const formData = new FormData();
    formData.append("audio", audioFile);



    try {
      const uploadResponse = await fetch("http://localhost:5000/upload", {
        method: "POST",
        body: formData,
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload: ${uploadResponse.statusText}`);
      }
  
      const uploadData = await uploadResponse.json();
      console.log("File uploaded successfully:", JSON.stringify(uploadData, null, 2));
      setAudioUrl(URL.createObjectURL(audioBlob)); // Preview the recorded audio here
  
       // Send the uploaded audio file to OpenAI for transcription
    const transcriptionFormData = new FormData();
    transcriptionFormData.append('file', audioBlob, 'audio.webm');

      // Send the uploaded audio file to OpenAI for transcription
    const transcriptionResponse = await fetch("http://localhost:5000/transcribe", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filePath: uploadData.filePath })
      });
  
      if (!transcriptionResponse.ok) {
        throw new Error(`Failed to transcribe: ${transcriptionResponse.statusText}`);
      }
  
      const transcriptionData = await transcriptionResponse.json();
      console.log("Transcription:", transcriptionData.transcription);
      setTranscription(transcriptionData.transcription); // Display transcription below the preview
  
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsStopping(false); // Reset flag after execution
    }
  };
  

  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const debouncedStopActualRecording = debounce(stopActualRecording, 300);

  const handleImageSelection = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="speaking-test-wrapper">
      {currentIndex === null ? (
        <section className="speaking-test-section speaking-test-instructions">
          <div className="speaking-test-instructions-heading">Speaking Test Instructions</div>
          <article>
            <ul className="speaking-test-instructions">
              <li>Use a timer to complete each task within the given time.</li>
              <li>Scores are not provided for this sample test, but answers are recorded.</li>
              <li>You can click "Next" to move to the next task.</li>
            </ul>
            <div>
              <button onClick={startTest}>START</button>
            </div>
          </article>
        </section>
      ) : (
        <section className="speaking-test-section speaking-test-question">
          <article>
            <table className="speaking-test-question">
              <tbody>
                <tr className="task-details">
                  <th className="task-info">Task Number: {currentQuestion?.taskNumber}</th>
                  <th className="task-info right-align">
                    Preparation: {currentQuestion?.preparationTime || 0} seconds
                    Recording: {currentQuestion?.recordingTime || 0} seconds
                  </th>
                  <button className="next-button" onClick={nextQuestion}>NEXT</button>
                </tr>
                <tr>
                  <td colSpan={2} className="task-title">
                    Task Title: {currentQuestion?.taskTitle}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="question-title">
                    Question Title: {currentQuestion?.questionTitle || ''}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="question-content">
                    {currentIndex === 4 && showThirdImage ? (
                      currentQuestion?.questionContentExtra || ''
                    ) : (
                      currentQuestion?.questionContent || ''
                    )}
                  </td>
                </tr>
                {/* Extra content */}
                {currentQuestion?.extra && (
                  <tr>
                    <td colSpan={2} className="extra-content">
                      {currentQuestion.extra}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />

            {/* Image Selection for Task 5 */}
            <div className="speaking-test-picture-area">
                {currentIndex === 4 ? (
                  <>
                    {!showThirdImage ? (
                      <>
                        <figure>
                          <img
                            src={currentQuestion.img}
                            alt=""
                            onClick={() => handleImageSelection('img1')}
                            style={{ border: selectedImage === 'img1' ? '2px solid green' : 'none' }}
                          />
                          <figcaption>{currentQuestion.imgCaption || ''}</figcaption>
                        </figure>
                        <figure>
                          <img
                            src={currentQuestion.img2}
                            alt=""
                            onClick={() => handleImageSelection('img2')}
                            style={{ border: selectedImage === 'img2' ? '2px solid green' : 'none' }}
                          />
                          <figcaption>{currentQuestion.img2Caption || ''}</figcaption>
                        </figure>
                      </>
                    ) : (
                      <>
                        {selectedImage === 'img1' && currentQuestion?.img && (
                          <figure>
                            <img
                              src={currentQuestion.img}
                              alt=""
                            />
                            <figcaption>{currentQuestion.imgCaption || ''}</figcaption>
                          </figure>
                        )}
                        {selectedImage === 'img2' && currentQuestion?.img2 && (
                          <figure>
                            <img
                              src={currentQuestion.img2}
                              alt=""
                            />
                            <figcaption>{currentQuestion.img2Caption || ''}</figcaption>
                          </figure>
                        )}
                        {currentQuestion?.img3 && (
                          <figure>
                            <img
                              src={currentQuestion.img3}
                              alt=""
                            />
                            <figcaption>{currentQuestion.img3Caption || ''}</figcaption>
                          </figure>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentQuestion?.img && (
                      <figure>
                        <img
                          src={currentQuestion.img}
                          alt=""
                        />
                      </figure>
                    )}
                    {currentQuestion?.img2 && (
                      <figure>
                        <img
                          src={currentQuestion.img2}
                          alt=""
                        />
                      </figure>
                    )}
                  </>
                )}
              </div>

              <div className="speaking-test-cnt-area">
              {prepareTime > 0 ? (
                <div className="speaking-test-countdown-form">
                  <span>Countdown:</span>
                  <br />
                  <span id="cntNumber">{prepareTime}</span>
                </div>
              ) : (
                <>
                  {isRecording ? (
                    <div className="speaking-test-recording-form">
                      <span>Recording:</span>
                      <br />
                      <progress
                        className="speaking-test-progress-bar"
                        value={currentQuestion?.recordingTime - recordingTime}
                        max={currentQuestion?.recordingTime}
                      ></progress>
                    </div>
                  ) : (
                    <div id="doneMessage">Recording Done...</div>
                  )}
                </>
              )}
            </div>

            {/* Preview Recorded Audio */}
            {audioUrl && (
            <div className="audio-preview">
              <p>Preview your recording:</p>
              <audio controls src={audioUrl} />
              {transcription && (
                <div className="transcription-preview">
                  <p>Transcription:</p>
                  <p>{transcription}</p>
                </div>
              )}
            </div>
          )}


            <button className="speaking-test-back-button" onClick={goBack}>BACK</button>
          </article>
        </section>
      )}
    </div>
  );
};

export default SpeakingTest;
