import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./ForgotPassword.module.css"; 
import { toast } from 'react-toastify';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/users/forgotpassword', { email });
            toast.success(response.data.message);
            navigate('/login');
        } catch (error) {
            toast.error(error.response.data.message || "Error sending reset email");
        }
    };

    return (
        <div className={styles.container}>
            <h1>Forgot Password</h1>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputContainerfp}>
                    <label>EMAIL</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button type="submit" className={styles.submitBTN}>Send Reset Link</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
