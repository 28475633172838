
import Recorder from "./Recorder";

const URL = window.URL || window.webkitURL;
let gumStream;
let rec;
let input;
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext;

// State Variables
let finishYN = false;
let objQuestion = {}; // Initialize with default or import data
let taskNumber = 0;
let cnt = 0;
let myVar;
let countType = 'P'; // P: Preparation, R: Recording
let globalPSec = 0;
let globalRSec = 0;
let specialTask5 = 0; // 0: Selecting, 1: Preparation, Recording
let globalSelectImg = ""; // for Task5

export const initializeQuestions = (questions) => {
  objQuestion = questions;
};

export const startText = () => {
  document.getElementById("instructions").style.display = "none";
  document.getElementById("question").style.display = "block";
  setupPage(taskNumber);
};

export const setupPage = (task) => {
  globalPSec = Number(objQuestion.QUESTION[task].PSEC);
  globalRSec = Number(objQuestion.QUESTION[task].RSEC);

  document.getElementById("questionTitle").innerText = objQuestion.QUESTION[task].QUESTIONTITLE;
  document.getElementById("questionContent").innerText = objQuestion.QUESTION[task].QUESTIONCONTENT;
  document.getElementById("prepareTime").innerText = globalPSec;
  document.getElementById("recordingTime").innerText = globalRSec;

  if (task === 3 || task === 4) {
    document.getElementById("pictureArea").style.display = "block";
    document.getElementById("pictureImg").src = objQuestion.QUESTION[task].IMG;
    document.getElementById("pictureImg").style.width = "400px";
  } else if (task === 5) {
    document.getElementById("pictureArea").style.display = "block";
    document.getElementById("pictureImg").src = objQuestion.QUESTION[task].IMG;
    document.getElementById("pictureImg").style.width = "300px";
    document.getElementById("pictureImgCaption").innerHTML = objQuestion.QUESTION[task].IMGCAPTION;
    document.getElementById("pictureImg2Fig").style.display = "block";
    document.getElementById("pictureImg2").src = objQuestion.QUESTION[task].IMG2;
    document.getElementById("pictureImg2").style.width = "300px";
    document.getElementById("pictureImg2Caption").innerHTML = objQuestion.QUESTION[task].IMG2CAPTION;
  } else if (task === 6 || task === 7) {
    document.getElementById("questionContentExtra").innerHTML = objQuestion.QUESTION[task].QUESTIONCONTENTEXTRA;
  } else if (task === 8) {
    document.getElementById("pictureArea").style.display = "block";
    document.getElementById("pictureImg").src = objQuestion.QUESTION[task].IMG;
    document.getElementById("pictureImg").style.width = "400px";
  }

  run();
};

export const specialCaseTask5 = () => {
  specialTask5 = 1;
  globalPSec = 60;
  globalRSec = 60;

  document.getElementById("questionContent").innerText = objQuestion.QUESTION[taskNumber].SPECIALCONTENT;
  document.getElementById("prepareTime").innerText = globalPSec;
  document.getElementById("recordingTime").innerText = globalRSec;

  if (globalSelectImg === '') {
    const rSelect = Math.floor((Math.random() * 2) + 1);
    globalSelectImg = rSelect === 1 ? 'pictureImgFig' : 'pictureImg2Fig';
    document.getElementById(globalSelectImg).style.backgroundColor = "#cddcf4";
  }

  if (globalSelectImg === 'pictureImgFig') {
    document.getElementById("pictureImg2").src = objQuestion.QUESTION[taskNumber].IMG3;
    document.getElementById("pictureImg2Caption").innerHTML = objQuestion.QUESTION[taskNumber].IMG3CAPTION;
  } else if (globalSelectImg === 'pictureImg2Fig') {
    document.getElementById("pictureImg").src = objQuestion.QUESTION[taskNumber].IMG3;
    document.getElementById("pictureImgCaption").innerHTML = objQuestion.QUESTION[taskNumber].IMG3CAPTION;
  }
};

export const selectPicture = (id) => {
  if (taskNumber === 5 && specialTask5 === 0) {
    globalSelectImg = id;
    document.getElementById('pictureImgFig').style.backgroundColor = id === 'pictureImgFig' ? "#cddcf4" : "#FFFFFF";
    document.getElementById('pictureImg2Fig').style.backgroundColor = id === 'pictureImg2Fig' ? "#cddcf4" : "#FFFFFF";
  }
};

export const restart = () => {
  reset();
  setupPage(taskNumber);
};

export const stop = () => {
  clearInterval(myVar);
};

export const before = () => {
  if (taskNumber > 0) {
    reset();
    setupPage(--taskNumber);
  }
};

export const next = () => {
  reset();
  if (taskNumber < 8) {
    taskNumber++;
    setupPage(taskNumber);
  } else {
    if (window.confirm("Will you try again?")) {
      taskNumber = 0;
      setupPage(taskNumber);
    } else {
      alert("Good Luck");
    }
  }
};

let timerInterval;

export const startTimer = (callback, duration) => {
  let timeLeft = duration;
  
  timerInterval = setInterval(() => {
    if (timeLeft <= 0) {
      clearInterval(timerInterval);
      if (callback) callback();
    } else {
      timeLeft--;
      console.log(`Time left: ${timeLeft} seconds`); // You can update the UI or handle timer updates here
    }
  }, 1000);
};

export const stopTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
};

export const reset = () => {
  cnt = 0;
  countType = 'P';
  globalPSec = 0;
  globalRSec = 0;
  document.getElementById("questionTitle").innerText = "";
  document.getElementById("questionContent").innerText = "";
  document.getElementById("prepareTime").innerText = 0;
  document.getElementById("recordingTime").innerText = 0;
  document.getElementById("progressBar").style.display = "none";
  document.getElementById("questionContentExtra").innerHTML = "";
  document.getElementById("pictureArea").style.display = "none";
  document.getElementById("cntName").innerHTML = "";
  document.getElementById("cntNumber").innerHTML = "";
  document.getElementById("cntNumber").style.display = "block";
  document.getElementById("pictureImg").src = "";
  document.getElementById("pictureImg").style.width = "300px";
  document.getElementById("pictureImgCaption").innerText = "";
  document.getElementById("pictureImg2").src = "";
  document.getElementById("pictureImg2").style.width = "300px";
  document.getElementById("pictureImg2Caption").innerText = "";
  document.getElementById("pictureImg2Fig").style.display = "none";
  document.getElementById("pictureImgFig").style.backgroundColor = "#FFFFFF";
  document.getElementById("pictureImg2Fig").style.backgroundColor = "#FFFFFF";
  globalSelectImg = "";
  clearInterval(myVar);

  if (rec) {
    rec.stop();
    rec.clear();
  }

  finishYN = false;

  document.getElementById("formats").innerHTML = "";
  document.getElementById("recordingsList").innerHTML = "";
};

export const run = () => {
  document.getElementById("cntName").innerText = "Preparation Time";
  cnt = globalPSec;
  const view = document.getElementById("cntNumber");
  view.innerText = cnt;

  myVar = setInterval(countdown, 1000);
};

const countdown = () => {
  const view = document.getElementById("cntNumber");

  if (cnt > 0) {
    cnt--;
    if (countType === 'P') {
      view.innerText = cnt;
    } else if (countType === 'R') {
      changeProgressBar(cnt);
    }
  } else {
    if (countType === 'P') {
      if (taskNumber === 5 && specialTask5 === 0) {
        specialCaseTask5();
        cnt = globalRSec + 1;
      } else {
        document.getElementById("cntNumber").style.display = "none";
        document.getElementById("progressBar").style.display = "block";
        countType = 'R';
        document.getElementById("cntName").innerText = "Recording";
        cnt = globalRSec + 1;
        playVoice('StartSpeakingNow');
        startRecording();
      }
    } else if (countType === 'R') {
      clearInterval(myVar);
      specialTask5 = 0;
      playVoice('TimeIsUp');
      finishYN = true;
      stopRecording();
    }
  }
};

export const startRecording = (audioContextRef, gumStreamRef, recRef, setRecordingTime) => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  audioContextRef.current = new AudioContext();

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      gumStreamRef.current = stream;
      const input = audioContextRef.current.createMediaStreamSource(stream);

      recRef.current = new Recorder(input, { numChannels: 1 });
      recRef.current.record();
    })
    .catch((err) => {
      console.error("Error accessing microphone:", err);
    });
};

export const stopRecording = (recRef) => {
  return new Promise((resolve) => {
    recRef.current.stop();
    recRef.current.exportWAV((blob) => {
      resolve(blob);
    });
  });
};

const createDownloadLink = (blob) => {
  const url = URL.createObjectURL(blob);
  const au = document.createElement('audio');
  const li = document.createElement('li');
  const link = document.createElement('a');

  const filename = new Date().toISOString();

  au.controls = true;
  au.src = url;

  link.href = url;
  link.download = `${filename}.wav`;
  link.innerHTML = "<br/>Save to disk";

  li.appendChild(au);
  li.appendChild(link);
  document.getElementById('recordingsList').appendChild(li);

  //link.click();
};

const changeProgressBar = (cnt) => {
  if (globalRSec > 0) {
    const progress = globalRSec - cnt;
    const percent = Math.round((progress / globalRSec) * 100);
    document.getElementById("progressBar").value = percent;
  }
};

const playVoice = (fileName) => {
  const audio = new Audio(`mp3/${fileName}.mp3`);
  audio.play();
};


