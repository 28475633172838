import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./ResetPassword.module.css";
import { toast } from 'react-toastify';

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(`http://localhost:5000/users/reset-password/${token}`, { newPassword });
            toast.success(response.data);
            navigate('/login');
        } catch (error) {
            toast.error(error.response.data || "Error resetting password");
        }
    };

    return (
        <div className={styles.container}>
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputContainer}>
                    <label>New Password</label>
                    <input
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className={styles.submitBTN}>Reset Password</button>
            </form>
        </div>
    );
}

export default ResetPassword;
