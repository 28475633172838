import React from 'react';
import { Link } from 'react-router-dom';
import Engdemy from "../../assets/Images/Engdemy.png";
import './AboutPage.css';

function AboutPage() {
    return (
        <div className="about-container h-screen flex lg:px-8 px-4 pb-8 lg:pb-0 flex-col lg:flex-row justify-around items-center bg-gray-900">
            <div className="about-content lg:px-4 md:px-4 space-y-8 lg:w-1/2 z-10">
                <h1 className="lg:text-5xl text-2xl text-white font-semibold">
                    About <span className="text-yellow-500 font-bold">Us</span>
                </h1>
                <p className="text-gray-200 lg:text-xl tracking-wider">
                    We are dedicated to providing high-quality educational content and resources for students to excel in their studies. Our mission is to make learning accessible and enjoyable for everyone.
                </p>
                <div className="about-buttons flex gap-4 lg:flex-row md:flex-row items-center">
                    <Link to={'/course'} className="w-fit">
                        <button className="button explore">
                            Explore Courses
                        </button>
                    </Link>
                    <Link to={'/contact'} className="w-fit">
                        <button className="button contact">
                            Contact Us
                        </button>
                    </Link>
                </div>
            </div>
            <div className="about-image-container lg:w-1/2">
                <img src={Engdemy} alt="About" className="about-image w-full h-full bg-transparent" />
            </div>
        </div>
    );
}

export default AboutPage;
