
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import HomePage from "./pages/HomePage"; 
import Login from "./componets/login";
import Signup from "./componets/signup";
import ProfilePage from "./componets/profile/ProfilePage";
import CoursePage from "./componets/course/CoursePage";
import ContactPage from "./componets/contact/Contact";
import AboutPage from "./componets/about/About";
import HomeLayout from "../src/Layouts/HomeLayout";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { AUTH } from './redux/const/actionsTypes';
import ForgotPassword from './componets/forgotpassword/ForgotPassword';
import ResetPassword from './componets/resetpassword/ResetPassword';
import SpeakingTest from './Questions/Celpip/SpeakingTest';
import CelpipHome from "./pages/CelpipHome"; 



function App() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("user_info"));
  if (localUser) {
      dispatch({ type: AUTH, data: localUser });
  }
  return (
    <div className="App">
      <HomeLayout/>
      <ToastContainer position="top-right" autoClose={3000} />
      <Routes>
      <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/course" element={<CoursePage/>}/>
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>

        <Route path="/celpip" element={<CelpipHome/>}/>
        <Route path="/practice" element={<SpeakingTest/>}/>


        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

export default App;
