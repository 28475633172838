import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import './CoursePage.css';

function CoursePage  ({ auth }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.authData) {
            navigate('/login');
        }
    }, [auth, navigate]);

    return (
        <div className="course-page">
            <h1>All Courses</h1>
            <div className="course-list">
                <div className="course-card">
                    <h2>Course 1</h2>
                    <p>Description of Course 1.</p>
                </div>
                <div className="course-card">
                    <h2>Course 2</h2>
                    <p>Description of Course 2.</p>
                </div>
                <div className="course-card">
                    <h2>Course 3</h2>
                    <p>Description of Course 3.</p>
                </div>
                {/* Add more course cards as needed */}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(CoursePage);
